import config from "../config";

export function getProductPrice(plan) {
    return config.prices[plan];
}

export function getProductTitle(plan) {
    switch (plan) {
        case "ios_basic":
            return "iOS Basic";
        case "ios_pro":
            return "iOS Pro";
        case "macos_basic":
            return "macOS Basic";
        case "macos_pro":
            return "macOS Pro";
        default:
            return "unknown";
    }
}
