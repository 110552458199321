<template>
  <div class="caution">
    <div class="header">
      <h3 class="title">
        <i class="icon icon-caution mr-1"></i>
        CAUTION
      </h3>
    </div>
    <ul>
      <li>Always take care of your privacy in the jailbreak world.</li>
      <li>Always know well what you are doing to your device.</li>
      <li>
        We declare exemption of the security problems caused by improper use and
        rights infringement to the third-parties by using AutoTouch.
      </li>
    </ul>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>
