<template>
    <div id="app">
        <div class="loading-overlay" v-if="showLoading">
            <b-spinner class="loading" label="Loading..."></b-spinner>
        </div>
        <Navbar class="top mb-3" />
        <router-view />
        <notifications group="main" position="top center" />
    </div>
</template>

<script>
import Navbar from "./components/Navbar.vue";
import { mapGetters } from "vuex";

export default {
    name: "app",
    components: {
        Navbar
    },
    created() {
        const id = this.$route.query.id;
        if (id) {
            this.$store.commit("setCurrentDeviceId", id);
        }
        const lang = this.$route.query.lang;
        if (lang) {
            let lang_;
            if (lang.toLowerCase() == "ja") {
                lang_ = "ja";
            } else {
                lang_ = "en";
            }
            if (lang_) {
                this.$store.commit("setLanguage", lang_);
            }
        }
    },
    computed: {
        ...mapGetters({
            showLoading: "getShowLoading"
        })
    }
};
</script>

<style lang="scss">
.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.loading {
    // You can add additional styles for the spinner here if needed
}
</style>
