import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from "axios";
import './version';

import './style.scss'

import BootstrapVue from 'bootstrap-vue'
Vue.use(BootstrapVue)

// Notifications
import Notifications from 'vue-notification';
Vue.use(Notifications);

import config from "../config";
axios.defaults.baseURL = config.server;

// http request interception
axios.interceptors.request.use(
    config => {
        const token = localStorage.getItem("accessToken");
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    err => {
        return Promise.reject(err);
    }
);

// Add a response interceptor
axios.interceptors.response.use(
    function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
    },
    async function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        const originalRequest = error.config;

        if (error.response.status === 401) {
            if (originalRequest.url.includes("/api/v1/auth/refresh_token")) {
                authService.logout();
                return Promise.reject(error);
            } else if (!originalRequest._retry) {
                originalRequest._retry = true;
                await refresh_token();
                return axios(originalRequest);
            } else {
                Vue.notify({
                    group: "main",
                    type: "error",
                    text: "Permission denied!"
                });
                router.push("/login");
            }
        } else if (error.response.status === 400 || error.response.status === 403) {
            Vue.notify({
                group: "main",
                type: "error",
                text: error.response.data
            });
        }

        return Promise.reject(error);
    }
);

async function refresh_token() {
    let refreshToken = localStorage.getItem("refreshToken");
    if (!refreshToken) return;

    let resp = await axios({
        url: `${config.server}/api/v1/auth/refresh_token`,
        method: "post",
        data: {
            refresh_token: refreshToken,
        },
    });

    if (resp.status == 200) {
        authService.setRefreshedTokens(resp.data.access_token, resp.data.refresh_token);
    } else {
        Vue.notify({
            group: 'main',
            type: 'error',
            text: 'Failed to refresh token!'
        });
    }
}

// 路由防卫
router.beforeEach((to, from, next) => {
    // 权限
    let titleArr = ["Licenses", "Statistics"];
    if (titleArr.indexOf(to.meta.title) > -1) {
        if (store.state.project.projectName == null) {
            document.title = to.meta.title;
        } else {
            document.title = store.state.project.projectName + "­­­­­­­－" + to.meta.title;
        }
    } else {
        if (to.meta.title) {
            document.title = to.meta.title;
        } else {
            document.title = "AutoTouch";
        }
    }
    if (to.meta.requireAuth) {
        let isLogin = localStorage.getItem("accessToken");
        if (isLogin || titleArr.indexOf(to.name) != "-1") {
            next();
        } else {
            next();
            // store.commit('logout');
            // next({
            //   path: '/login',
            //   query: {
            //     redirect: to.fullPath
            //   }
            // })
        }
    } else {
        next();
    }
});

Vue.prototype.$http = axios;

Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')
