import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        language: 'en',
        currentDeviceId: null,
        user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : { id: '' },
        loggedIn: localStorage.getItem('accessToken') != null,
        showLoading: false
    },
    mutations: {
        setLanguage(state, language) {
            state.language = language
        },
        setCurrentDeviceId(state, deviceId) {
            state.currentDeviceId = deviceId
        },
        login(state) {
            state.loggedIn = true;
            state.user = JSON.parse(localStorage.getItem('user'));
        },
        logout(state) {
            state.loggedIn = false;
            state.user = { id: null };
        },
        setShowLoading(state, show) {
            state.showLoading = show;
        }
    },
    getters: {
        isLoggedIn: state => {
            return state.loggedIn;
        },
        getUser: state => {
            return state.user;
        },
        getShowLoading: state => {
            return state.showLoading;
        }
    },
    actions: {

    }
})
