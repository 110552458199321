const prices = {
    ios_basic: 1990,
    ios_pro: 2990,
    macos_basic: 6999,
    macos_pro: 8999
}

const configProd = {
    server: 'https://autotouch.net',
    payPalClientId: 'AWE2Aw13lBPWZsUQRbIqpu1bPL9lvewu7wLMKMcgHyyjZdrSqOkSeg5W4CsEQKoOua6SJGOi3kgAUQ4V',
    paypal: {
        pdt: 'https://www.paypal.com/cgi-bin/webscr',
        notifyUrl: 'https://autotouch.net/api/v1/paypal/ipn',
        returnUrl: 'https://license.autotouch.net/purchase/success'
    },
    prices
}

const configDev = {
    server: 'https://autotouch.net',
    payPalClientId: 'AeFAwsLjD3eAbak7UKELhDsik-8xJTlvfzkyvS_dMvxtUFWMwqaz4WVpzuo7hQohQrqRVfOVZJINQkjE',
    paypal: {
        pdt: 'https://www.sandbox.paypal.com/cgi-bin/webscr',
        notifyUrl: 'https://autotouch.dev/api/v1/paypal/ipn',
        returnUrl: 'https://license.autotouch.dev/purchase/success'
        // returnUrl: 'http://localhost:8080/purchase/success'
    },
    prices
}

export default process.env.NODE_ENV === 'production' ? configProd : configDev;
