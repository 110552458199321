<template>
    <b-navbar
        toggleable="lg"
        type="dark"
        variant="info"
        class="navbar navbar-expand-sm navbar-dark bg-dark"
    >
        <div class="container-fluid">
            <router-link class="navbar-brand" to="/">AutoTouch</router-link>
            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

            <b-collapse id="nav-collapse" is-nav>
                <b-navbar-nav v-if="isLoggedIn">
                    <!-- <li
                        class="nav-item"
                        v-bind:class="{
                            active: $route.path === '/'
                        }"
                    >
                        <router-link class="nav-link" to="/">Home</router-link>
                    </li> -->
                </b-navbar-nav>
                <b-navbar-nav class="ml-auto">
                    <!-- Check that the SDK client is not currently loading before accessing is methods -->
                    <!-- show login when not authenticated -->
                    <li class="nav-item" v-if="!isLoggedIn">
                        <router-link class="nav-link" to="/login"
                            >Login</router-link
                        >
                    </li>

                    <!-- show logout when authenticated -->
                    <template v-else>
                        <b-nav-item-dropdown right>
                            <template #button-content>
                                <img
                                    :src="avatarUrl"
                                    :alt="user.email"
                                    class="avatar"
                                />
                                <em>{{ user.email }}</em>
                            </template>

                            <b-dropdown-item :to="{ name: 'password' }">
                                Set Password
                            </b-dropdown-item>
                            <b-dropdown-item @click="logout"
                                >Logout</b-dropdown-item
                            >
                        </b-nav-item-dropdown>
                    </template>
                </b-navbar-nav>
            </b-collapse>
        </div>
    </b-navbar>
</template>

<script>
import md5 from "md5";
import { mapGetters } from "vuex";
import { authService } from "../service/index";

export default {
    name: "Navbar",
    data() {
        return {};
    },
    computed: {
        ...mapGetters({
            user: "getUser",
            isLoggedIn: "isLoggedIn"
        }),
        avatarUrl() {
            const hash = md5(this.user.email.trim().toLowerCase());
            return `https://www.gravatar.com/avatar/${hash}?s=32&d=mp`;
        }
    },
    methods: {
        // Log the user out
        logout() {
            authService.logout({
                group: "main",
                type: "success",
                text: "Logged out!"
            });
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.avatar {
    width: 30px;
    height: 30px;
    border-radius: 15px;
    border: 2px solid white;
    margin-right: 5px;
}
</style>
