<template>
  <div class="container" v-cloak>
    <div class="row">
      <div class="col-12 px-3">
        <div class="title-field">
          <div class="title">{{ tran("AutoTouch Licenses") }}</div>
        </div>
      </div>
    </div>
    <div class="row py-3">
      <div class="col-12">
        <div class="instructions p-3">
          <div class="d-flex justify-content-center">
            <h3 class="title">Instructions</h3>
          </div>
          <ul>
            <li>
              All licenses have one year validity period which starts from the
              first activation, not the purchasing time.
            </li>
            <li>
              License is transferable: a license can be used to activate another
              device, meanwhile the last activated device will be diactivated.
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row py-3">
      <div class="col-12">
        <div class="px-0">
          <router-link to="/manage" class="d-flex flex-row text-decoration-none">
            <button class="btn-common btn-green btn-manage mr-2">
              Manage My Licenses
            </button>
            <button class="btn-common btn-renew">
              Renew
            </button>
          </router-link>
        </div>
      </div>
    </div>
    <div class="row d-flex justify-content-center">
        <div class="col-12 col-md-6 py-3">
            <CardiOSBasic/>
        </div>
        <!--
        <div class="col-12 col-md-6 py-3">
            <CardiOSPro/>
        </div>
        <div class="col-12 col-md-4 py-3">
            <CardMacOSBasic/>
        </div>
        <div class="col-12 col-md-6 py-3">
            <CardMacOSPro/>
        </div>
        -->
    </div>
    <div class="row py-3">
      <div class="col-12 px-3">
          <Caution />
      </div>
    </div>
    <div class="row">
      <div class="col-12 px-3">
        <div class="community p-3">
          <h3 class="title">Community</h3>
          <div class="links">
            <a href="https://discordapp.com/invite/GUXu4uZ">Discord</a>
            <a href="https://t.me/joinchat/EGJlcBFK-ArD5hVy7NHDew">Telegram</a>
            <a href="https://www.reddit.com/r/autotouch/">Reddit</a>
            <a href="https://twitter.com/kentkrantz">Twitter: @kentkrantz</a>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 px-3">
        <div class="copyright p-3">
          <div>2023 © AutoTouch</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import config from "../../config";
import axios from "axios";
import moment from "moment";
import i18n from "../i18n";

import CardiOSBasic from '../components/CardiOSBasic';
import CardiOSPro from '../components/CardiOSPro';
import CardMacOSBasic from '../components/CardMacOSBasic';
import CardMacOSPro from '../components/CardMacOSPro';
import Caution from '../components/Caution';

export default {
  name: "Home",
  components: {
      CardiOSBasic,
      CardiOSPro,
      CardMacOSBasic,
      CardMacOSPro,
      Caution
  },
  data() {
    return {
      lang: this.$store.state.language,
      deviceId: this.$store.state.currentDeviceId,

      isCurrentDeviceActivated: false,
      queried: false,
      loading: false,
    };
  },
  created() {
    if (this.deviceId) {
      this.checkCurrentDevice();
    }
  },
  mounted: function () {
    this.loading = true;
  },
  methods: {
    tran(key) {
      return i18n[this.lang][key];
    },
    timeFormat(t) {
      return moment.utc(t).local().format("YYYY-MM-DD HH:mm:ss");
    },
    checkCurrentDevice() {
      let self = this;

      if (!self.deviceId) return;

      self.loading = true;

      const url = `${config.server}/api/v1/license?id=${self.deviceId}`;

      axios
        .get(url)
        .then(function (response) {
          const { validated } = response.data;
          self.isCurrentDeviceActivated = validated;
        })
        .catch(function (error) {
          console.error(error);
        })
        .finally(function () {
          self.queried = true;
          self.loading = false;
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.purchase-button-field {
  position: absolute;
  bottom: 16px;
  right: 16px;
}

</style>
