const i18n = {
    "en": {
        "AutoTouch Licenses": "AutoTouch Licenses",
        "Instructions": "Instructions",
        "Current device is activated!": "Current device is activated! Please download the license file to your device.",
        "Buy with PayPal": "Buy with PayPal",
        "Buy with Taobao": "Buy with Taobao",
        "Activate Current Device": "Activate Current Device",
        "Query My Licenses": "Query My Licenses",
        "Query by License Key": "Query by License Key",
        "Query by Payer Email": "Query by Payer Email",
        "Query by SN of Activated Device": "Query by SN of Activated Device",
        "License Key you want to use:": "License Key you want to use:",
        "LICENSE_ACTIVATE_ANOTHER_DEVICE_WARNNING": "This license can be used with no time limitation on that activated device, if you activated another device with it, it would become 1 year validity, are you sure to go on?",
        "Serial Number of the device you want to activate:": "Serial Number of the device you want to activate:",
        "No license found!": "No license found!",
        "License": "License",
        "Activated Device SN": "Activated Device SN",
        "Operations": "Operations",
        "License Key": "License Key",
        "Expires": "Expires",
        "forever": "forever",
        "Bought Time": "Bought Time",
        "PayPal Account": "PayPal Account",
        "Payment Method": "Payment Method",
        "current device": "current device",
        "Activate Another Device": "Activate Another Device",
        "Select all and copy here:": "Select all and copy here:",
        "Older than a year!": "Older than a year",
        "One activation a day!": "Only once activation a day",
        "Are you sure to activate current device with this license?": "Are you sure to activate current device with this license?",
        "NEW_AGREEMENT1": `From 2015-08-25, the new purchased licenses will be able to be transferred to other devices,
							and will have one year validity from buying. New license can be used to activate only one device at a time,
							when you activate another device, the last one will become deactivated.
							When the validity is expired, the license becomes invalid. Following the old agreement,
						    the licenses you bought before still can be used with no time limitation on those already activated devices,
						    but if you activate other devices with them, they will change to new type licenses,
						    will become transferable and have one year validity from buying.
						    The licenses older than a year are not able to change to the new type licenses.
						    In addition, the license key can make only one activation in a day.`,
        "NEW_AGREEMENT2": `From every purchase you will get a license key looks like "FECS3-6DEJ8-EF39D-2JDU7",
							with which you can activate one device or another, but only one a time.
							Every activation needs a license key and a device Serial Number.
							If you make the purchase inside AutoTouch,
							the Serial Number of your device will be sent to the server belong with the payment,
							so that device will be activated automatically when the license key was generated.
							If not inside AutoTouch, you should provide the Serial Number by yourself.
							You can query your purchased licenses with a license key, or with the PayPal payer email,
							or Serial Number of one activated devices,
							purchases with Taobao can not be queried connectedly now, you can query them with license key or Serial Number.`,
        "NEW_AGREEMENT3": "",
        "NEW_AGREEMENT4": "",
        "NEW_AGREEMENT5": "",
        "$9.99 USD per year": "$9.99 USD per year",
        "Transferable from one device to another": "Transferable from one device to another",
        "Status": "Status",
        "Expired": "Expired",
        "REFUNDED": "Refunded",
        "REVOKED": "Revoked",
        "NORMAL": "Normal",
    }
}

export default i18n;
