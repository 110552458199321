import store from '../store'
import router from '../router'
import Vue from 'vue'

export const authService = {
    login,
    setRefreshedTokens,
    logout
};

function login(accessToken, refreshToken, user) {
    if (accessToken && refreshToken && user) {
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('refreshToken', refreshToken);
        localStorage.setItem('user', JSON.stringify(user));
        store.commit('login');
    } else {
        alert(`Got invalid accessToken(${accessToken}) and refreshToken(${refreshToken} and user(${user}))!`);
    }
}

function setRefreshedTokens(accessToken, refreshToken) {
    if (accessToken && refreshToken) {
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('refreshToken', refreshToken);
        store.commit('login');
    } else {
        alert(`Got invalid accessToken(${accessToken}) and refreshToken(${refreshToken})!`);
    }
}

/**
 * Logout
 * @param {
            group: 'main',
            type: 'success',
            text: 'Logged out!'
        } tip 
 */
function logout(tip) {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('user');
    store.commit('logout');
    if (tip) {
        Vue.notify(tip);
    }

    router.push('/login');
}