<template>
  <div class="card-plan macos-basic p-3 position-relative">
    <div class="d-flex justify-content-between align-items-baseline">
      <h3 class="title">MacOS Basic</h3>
      <div></div>
      <h3 class="price">
        ${{price/100}}
        <span class="unit">/ Year</span>
      </h3>
    </div>
    <ul>
      <li>
        <div class>
          <a
            href="https://docs.autotouch.net/index.html"
            class="d-flex align-items-top"
          >
            <i class="icon icon-checked mr-2"></i>
            Recording and replaying of mouse down, up, move, drag, scroll button
            scrolling, keyboard clicking, etc.
            <i class="icon icon-question ml-1"></i>
          </a>
        </div>
      </li>
      <li>
        <div class>
          <a
            href="https://docs.autotouch.net/js/api.html"
            class="d-flex align-items-center"
          >
            <i class="icon icon-checked mr-2"></i>
            Extended functions
            <i class="icon icon-question ml-1"></i>
          </a>
        </div>
      </li>
      <li>
        <div class>
          <a
            href="https://docs.autotouch.net/js/index.html"
            class="d-flex align-items-center"
          >
            <i class="icon icon-checked mr-2"></i>
            JavaScript language engine
            <i class="icon icon-question ml-1"></i>
          </a>
        </div>
      </li>
      <li>
        <div class>
          <a
            href="https://docs.autotouch.net/js/jsbridge.html"
            class="d-flex align-items-center"
          >
            <i class="icon icon-unchecked mr-2"></i>
            JSBridge
            <i class="icon icon-radiation ml-1"></i>
            <i class="icon icon-question ml-1"></i>
          </a>
        </div>
      </li>
    </ul>
    <div class="d-flex justify-content-between">
      <router-link to="/purchase/macos_basic">
        <button class="btn-purchase">Purchase</button>
      </router-link>
      <a href="https://downloads.autotouch.net/macos/">
        <button class="btn-install">Go to install</button>
      </a>
    </div>
  </div>
</template>

<script>
import { getProductPrice } from "../helper";

export default {
  name: "CardMacOSBasic",
  computed: {
      price: function() {
          return getProductPrice('macos_basic');
      }
  }
};
</script>

<style>
</style>
